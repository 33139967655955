body { 
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-section{
  margin-top: 36px;
  margin-bottom: 36px;
}

.custom-hr {
  border: none;
  border-top: 3px double #333;
  color: #333;
  overflow: visible;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 5px;
  position: relative;
  width: 95%;
}

.footer {
  background-color: #332d2d;
  color: white;
}

.footer-hr {
  border: none;
  border-top: 3px double;
}

.hr-content {
  font-size: 32px;
  background: #fff;
  padding: 0 10px;
  position: absolute;
  border: 3px double #333;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
}